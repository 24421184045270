import React from "react";
import ReactApexChart from "react-apexcharts";

export const Bar = ({ data, labels }) => {
  const options = {
    colors: ["#1a3761", "#dbad50"],
    chart: {
      type: "bar",
      height: 350, //350
      width: 500, //500
    },
    plotOptions: {
      bar: {
        horizontal: true, //false
        columnWidth: "100%", //55%
        endingShape: "rounded",
      },
    },
    // dataLabels: {
    //   enabled: false,
    // },
    stroke: {
      show: true,
      width: 1, //2
      colors: ["transparent"],
    },
    xaxis: {
      categories: labels,
      title: {
        align:'flex',
        text: ""
      }
    },
    yaxis: {
      title: {
        text: "Count of Normative Documents",
      },
    },
    fill: {
      opacity: 1,
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={data}
      type="bar"
      height={215} //400
      width={"80%"} //100%
    />
  );
};

export default Bar;
