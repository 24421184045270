import React from "react";
import "./transparentContainer.css";
import Logo from "../logo/Logo";

const TransparentContainer = ({ showComponent, setShowComponent }) => {
  const handleClick = () => {
    setShowComponent(false);
  };

  return (
    <div className="transparent-container">
      <div className="transparent-box">
        <li>
        <h3 className="transparent-primary">
          CYBER NORMS OBSERVATORY
        </h3>
        <h4 className="transparent-subtitle" align = "center">What norms are there in cyberspace and which actors are trying to shape cyberspace?</h4>
        <h4 className="transparent-subtitle" align = "center">The Cyber Norms Observatory demonstrates how more than 900 existing norms connect cyberspace.</h4>   
        <h4 align = "center"></h4>    
        </li>
        <li>
        <h4 className="transparent-primary">
        </h4>
        </li>
        <ul className="transparent-list">
          <li>
            <h5 className="transparent-secondary" align = "left">
              ISSUER DIAGRAM
            </h5>
            <p className="transparent-description" align = "left">
            The diagram connects issuers of normative documents, showing how normative actors are connected in cyberspace. The diagram can be selected to show normative actors by stakeholder domain, by actor type, or by individual actor name. Click on a connection to show the connectivity profile between connected actors below the diagram.
            </p>
          </li>
          <li>
            <h5 className="transparent-secondary" align = "center">
              NORMS NETWORK
            </h5>
            <p className="transparent-description" align = "center">
            The network is the results of the natural language processing process and shows how similar norm statements from different normative documents and actors are connected. The network view can be filtered by actor and granularity level of the norm. With the group filter, the network can be colour coded in various ways.
            </p>
            <div className="transparent-btn" onClick={handleClick}>
              To the monitor
            </div>
          </li>
          <li>
            <h5 className="transparent-secondary" align = "right">METADATA & NORMATIVE STATEMENTS</h5>
            <p className="transparent-description" align = "right">
            By clicking on a norm in the network, the metadata shows the information on the normative actor, the norm category and topic, the origin document and the norm statement itself. The terminology might be the same in the various norms and standards regimes, but these terms might have different meanings, the norms and standards regimes are continually evolving.
            </p>
          </li>
        </ul>
      </div>
      <div>
      </div>
      <Logo/> 
    </div>

  );
};

export default TransparentContainer;