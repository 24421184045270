export const matrix1 = [
  [605, 365, 480, 558, 354],
  [8, 6, 7, 8, 7],
  [60, 16, 60, 60, 1],
  [144, 94, 128, 191, 123],
  [28, 18, 21, 42, 41],
];
export const matrix2 = [
  [
    133, 133, 90, 126, 78, 132, 127, 98, 37, 106, 122, 57, 26, 75, 77, 86, 52,
    75,
  ],
  [
    122, 122, 83, 113, 87, 119, 115, 101, 35, 72, 105, 70, 25, 82, 70, 102, 31,
    86,
  ],
  [12, 12, 10, 8, 5, 9, 11, 8, 1, 7, 7, 8, 2, 5, 8, 8, 3, 6],
  [
    123, 123, 77, 123, 73, 123, 122, 104, 27, 71, 116, 65, 26, 74, 78, 95, 57,
    74,
  ],
  [45, 43, 39, 43, 61, 45, 45, 36, 10, 30, 61, 36, 22, 41, 31, 36, 0, 61],
  [84, 84, 61, 78, 64, 83, 78, 77, 16, 44, 76, 52, 15, 63, 50, 72, 16, 66],
  [80, 80, 56, 77, 61, 75, 80, 66, 12, 31, 73, 66, 20, 56, 52, 69, 11, 54],
  [17, 17, 13, 16, 14, 17, 16, 16, 5, 7, 16, 12, 3, 12, 9, 14, 5, 11],
  [2, 2, 1, 2, 2, 2, 2, 1, 0, 2, 2, 1, 0, 2, 0, 2, 0, 2],
  [34, 34, 28, 33, 9, 34, 33, 5, 7, 33, 10, 25, 2, 9, 27, 9, 2, 32],
  [19, 21, 13, 21, 21, 19, 18, 15, 9, 10, 25, 13, 1, 16, 11, 15, 6, 21],
  [13, 13, 13, 12, 11, 12, 13, 11, 3, 5, 11, 11, 4, 11, 10, 12, 0, 10],
  [1, 1, 1, 1, 1, 1, 1, 1, 0, 1, 1, 1, 0, 1, 1, 1, 0, 1],
  [19, 19, 16, 19, 21, 19, 20, 17, 3, 16, 17, 15, 12, 22, 14, 19, 0, 18],
  [24, 24, 24, 24, 8, 24, 24, 22, 0, 19, 22, 10, 3, 8, 24, 8, 14, 10],
  [25, 25, 22, 22, 21, 22, 23, 21, 3, 3, 21, 22, 1, 21, 17, 21, 1, 19],
  [3, 3, 2, 3, 0, 3, 3, 3, 0, 2, 3, 0, 0, 0, 2, 1, 2, 0],
  [74, 77, 47, 78, 104, 74, 72, 34, 35, 52, 103, 50, 1, 53, 46, 55, 0, 121],
];

export const matrix3 = [
  [
    90, 11, 18, 21, 48, 9, 8, 53, 23, 1, 7, 3, 7, 19, 3, 22, 1, 9, 3, 8, 4, 80,
    1, 1, 17, 9, 18, 5, 37, 7, 2, 1, 11, 11, 9, 1, 19, 0, 22, 15, 28, 11, 3, 5,
    13, 3, 42, 0, 7, 1, 18, 7,
  ],
  [
    11, 11, 3, 3, 2, 1, 0, 2, 4, 0, 2, 0, 0, 0, 1, 6, 0, 0, 1, 0, 0, 2, 0, 0, 2,
    0, 3, 3, 15, 0, 0, 0, 2, 1, 0, 0, 0, 0, 14, 0, 16, 0, 2, 0, 0, 0, 0, 0, 0,
    0, 0, 3,
  ],
  [
    81, 11, 17, 11, 41, 7, 7, 41, 15, 1, 7, 3, 7, 14, 3, 21, 0, 9, 3, 6, 4, 69,
    0, 0, 11, 3, 14, 4, 27, 5, 1, 0, 10, 10, 6, 1, 16, 0, 22, 15, 24, 7, 2, 5,
    13, 3, 41, 0, 7, 1, 18, 6,
  ],
  [
    86, 11, 18, 22, 47, 9, 8, 49, 22, 1, 7, 3, 7, 17, 3, 22, 1, 8, 3, 8, 4, 71,
    1, 1, 15, 9, 17, 4, 37, 7, 2, 1, 11, 10, 9, 1, 19, 0, 22, 15, 27, 13, 3, 5,
    14, 3, 38, 0, 22, 1, 18, 9,
  ],
  [
    84, 11, 18, 15, 48, 9, 8, 53, 22, 1, 7, 3, 7, 16, 3, 21, 0, 9, 3, 8, 4, 77,
    0, 1, 15, 3, 19, 5, 40, 7, 2, 1, 29, 11, 8, 1, 17, 4, 24, 15, 27, 7, 3, 5,
    26, 4, 51, 4, 7, 1, 18, 6,
  ],
  [
    79, 11, 18, 14, 47, 4, 8, 48, 22, 1, 7, 3, 7, 14, 3, 21, 1, 9, 3, 7, 4, 71,
    0, 1, 15, 3, 18, 4, 37, 7, 2, 0, 10, 10, 8, 1, 19, 0, 22, 15, 26, 7, 3, 5,
    13, 3, 37, 0, 7, 1, 18, 7,
  ],
  [
    63, 0, 15, 9, 38, 7, 6, 36, 14, 1, 5, 3, 7, 11, 2, 12, 1, 7, 2, 5, 4, 53, 0,
    1, 11, 2, 9, 1, 8, 5, 2, 0, 8, 9, 8, 1, 17, 0, 8, 15, 6, 4, 0, 4, 13, 3, 37,
    0, 3, 1, 18, 4,
  ],
  [
    85, 11, 18, 20, 48, 9, 8, 56, 23, 1, 7, 3, 7, 16, 3, 21, 1, 9, 3, 8, 4, 80,
    0, 1, 16, 3, 19, 5, 40, 7, 2, 1, 29, 11, 9, 1, 17, 4, 24, 15, 27, 8, 3, 5,
    26, 4, 48, 4, 7, 1, 18, 9,
  ],
  [
    84, 11, 18, 14, 48, 9, 8, 53, 21, 1, 7, 3, 7, 16, 3, 21, 1, 9, 3, 8, 4, 74,
    0, 1, 16, 3, 19, 5, 40, 7, 2, 1, 29, 11, 8, 1, 17, 4, 24, 15, 27, 8, 3, 5,
    26, 4, 44, 4, 5, 1, 18, 9,
  ],
  [
    7, 0, 1, 4, 8, 4, 4, 11, 7, 0, 4, 2, 5, 1, 1, 9, 0, 3, 2, 0, 1, 23, 0, 0, 4,
    1, 5, 0, 3, 1, 0, 0, 0, 1, 4, 0, 2, 0, 5, 14, 0, 0, 0, 0, 8, 1, 18, 0, 0, 0,
    0, 2,
  ],
  [
    45, 11, 16, 8, 24, 6, 6, 18, 13, 1, 6, 3, 6, 7, 2, 15, 0, 6, 3, 4, 3, 33, 0,
    0, 7, 1, 8, 3, 20, 1, 1, 0, 8, 8, 4, 0, 5, 0, 19, 14, 19, 2, 2, 3, 12, 1,
    37, 0, 3, 0, 0, 6,
  ],
  [
    33, 0, 13, 5, 21, 5, 6, 15, 8, 1, 5, 2, 6, 6, 1, 9, 0, 4, 2, 2, 3, 31, 0, 0,
    4, 1, 5, 0, 5, 1, 1, 0, 6, 6, 4, 0, 4, 0, 5, 14, 3, 2, 0, 2, 12, 1, 37, 0,
    3, 0, 0, 2,
  ],
  [
    59, 0, 15, 7, 37, 6, 7, 34, 9, 1, 5, 3, 5, 11, 2, 12, 0, 7, 2, 4, 4, 62, 0,
    0, 7, 3, 10, 1, 7, 5, 1, 0, 8, 8, 6, 1, 16, 0, 8, 15, 6, 4, 0, 4, 13, 3, 37,
    0, 5, 1, 18, 2,
  ],
  [
    78, 0, 16, 11, 45, 8, 8, 48, 21, 1, 5, 3, 7, 18, 2, 17, 1, 9, 2, 8, 4, 73,
    1, 1, 14, 9, 15, 2, 22, 7, 2, 1, 9, 10, 8, 1, 18, 0, 8, 15, 12, 8, 1, 5, 13,
    3, 42, 0, 7, 1, 18, 6,
  ],
  [
    41, 11, 6, 10, 26, 6, 5, 30, 13, 1, 6, 2, 6, 6, 0, 18, 0, 6, 3, 2, 2, 45, 0,
    0, 9, 3, 13, 4, 21, 5, 0, 0, 4, 4, 6, 1, 14, 0, 22, 15, 20, 5, 2, 2, 9, 3,
    18, 0, 2, 1, 18, 5,
  ],
  [
    58, 11, 10, 12, 35, 8, 5, 41, 19, 1, 7, 2, 6, 13, 3, 21, 0, 9, 3, 6, 2, 59,
    1, 0, 13, 9, 18, 5, 32, 7, 0, 1, 5, 5, 6, 1, 16, 0, 22, 15, 24, 6, 3, 3, 10,
    3, 19, 0, 2, 1, 18, 6,
  ],
  [
    11, 0, 0, 2, 0, 1, 1, 10, 5, 0, 0, 0, 0, 6, 0, 0, 0, 2, 0, 1, 0, 11, 0, 1,
    5, 2, 2, 1, 2, 0, 1, 0, 0, 2, 2, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 1, 2,
  ],
  [
    71, 0, 16, 9, 44, 8, 8, 47, 20, 1, 5, 3, 7, 16, 2, 16, 1, 9, 2, 7, 4, 72, 0,
    1, 16, 3, 15, 2, 22, 7, 2, 0, 8, 10, 8, 1, 16, 0, 8, 15, 11, 10, 1, 5, 13,
    3, 37, 0, 3, 1, 18, 6,
  ],
  [
    18, 11, 4, 7, 10, 5, 4, 13, 11, 1, 6, 2, 5, 1, 2, 15, 0, 3, 2, 0, 1, 25, 0,
    0, 6, 1, 8, 3, 18, 1, 0, 0, 2, 2, 4, 0, 2, 0, 19, 14, 16, 0, 2, 0, 8, 1, 18,
    0, 0, 0, 0, 5,
  ],
  [
    71, 0, 15, 8, 39, 5, 4, 42, 13, 0, 1, 1, 2, 18, 1, 8, 1, 7, 0, 6, 3, 50, 1,
    1, 11, 8, 11, 1, 23, 6, 2, 2, 28, 9, 4, 1, 17, 4, 5, 1, 12, 8, 1, 5, 18, 4,
    35, 4, 7, 1, 18, 4,
  ],
  [
    56, 0, 15, 7, 36, 6, 7, 32, 9, 1, 5, 3, 7, 11, 2, 12, 0, 7, 2, 4, 2, 51, 0,
    0, 7, 2, 9, 1, 7, 5, 1, 0, 8, 7, 6, 1, 16, 0, 8, 15, 6, 4, 0, 4, 13, 3, 37,
    0, 3, 1, 18, 2,
  ],
  [
    85, 11, 18, 19, 47, 9, 8, 56, 22, 1, 7, 3, 7, 16, 3, 21, 1, 9, 3, 8, 4, 80,
    0, 1, 16, 3, 19, 5, 40, 7, 2, 1, 29, 10, 9, 1, 20, 4, 24, 15, 27, 10, 3, 5,
    27, 4, 45, 4, 8, 1, 18, 7,
  ],
  [
    5, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 1, 0, 0, 0, 1, 0, 0, 0, 0, 1,
    6, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0, 2, 0, 0, 0, 1, 1, 0, 0, 0, 0, 1, 0, 0, 0,
    0, 0,
  ],
  [
    6, 0, 0, 3, 2, 1, 1, 7, 5, 0, 0, 0, 0, 1, 0, 0, 1, 1, 0, 1, 0, 2, 0, 0, 4,
    0, 0, 0, 1, 0, 1, 0, 0, 2, 2, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    1, 2,
  ],
  [
    62, 11, 10, 14, 35, 9, 6, 46, 22, 1, 7, 2, 6, 17, 3, 22, 1, 9, 3, 8, 2, 66,
    1, 1, 16, 9, 18, 5, 35, 7, 1, 1, 5, 7, 8, 1, 17, 0, 22, 15, 25, 6, 3, 3, 11,
    3, 23, 0, 5, 1, 18, 7,
  ],
  [
    53, 0, 3, 15, 24, 5, 5, 34, 12, 1, 4, 2, 6, 13, 2, 13, 1, 6, 2, 4, 2, 66, 1,
    0, 8, 8, 11, 2, 7, 6, 0, 1, 3, 5, 7, 1, 16, 0, 8, 15, 6, 6, 0, 2, 9, 3, 19,
    0, 4, 1, 18, 3,
  ],
  [
    59, 11, 6, 11, 35, 7, 5, 46, 19, 1, 6, 2, 6, 12, 3, 21, 1, 8, 3, 5, 2, 66,
    0, 0, 14, 3, 18, 5, 38, 7, 0, 1, 23, 6, 6, 1, 14, 4, 24, 15, 24, 5, 3, 3,
    22, 4, 24, 4, 4, 1, 18, 6,
  ],
  [
    46, 11, 5, 6, 24, 3, 1, 26, 9, 0, 2, 0, 1, 9, 2, 12, 1, 4, 1, 2, 1, 31, 0,
    0, 10, 2, 12, 3, 22, 5, 0, 0, 4, 5, 2, 1, 12, 0, 17, 1, 22, 5, 3, 3, 4, 2,
    15, 0, 2, 1, 18, 4,
  ],
  [
    84, 11, 18, 14, 48, 9, 8, 53, 23, 1, 7, 3, 7, 16, 3, 21, 1, 9, 3, 8, 4, 74,
    0, 1, 16, 3, 19, 5, 40, 7, 2, 1, 29, 11, 8, 1, 17, 4, 24, 15, 27, 8, 3, 5,
    27, 4, 48, 4, 6, 1, 18, 9,
  ],
  [
    42, 0, 3, 8, 32, 6, 5, 37, 16, 1, 4, 2, 6, 8, 2, 15, 0, 7, 2, 4, 2, 58, 0,
    0, 12, 3, 15, 2, 20, 6, 1, 0, 2, 4, 6, 1, 14, 0, 8, 15, 7, 5, 1, 3, 10, 3,
    20, 0, 3, 1, 18, 2,
  ],
  [
    34, 0, 12, 4, 19, 2, 3, 15, 6, 0, 1, 1, 1, 5, 0, 0, 1, 1, 0, 3, 2, 12, 0, 1,
    5, 0, 0, 0, 4, 2, 0, 0, 7, 7, 3, 0, 3, 0, 0, 0, 3, 2, 0, 2, 10, 0, 25, 0, 4,
    0, 1, 2,
  ],
  [
    5, 0, 0, 1, 2, 0, 0, 4, 1, 0, 0, 0, 0, 3, 0, 1, 0, 0, 0, 2, 0, 1, 1, 0, 1,
    6, 1, 0, 4, 0, 0, 0, 20, 0, 0, 0, 2, 4, 2, 0, 1, 1, 0, 0, 13, 1, 7, 4, 0, 0,
    0, 0,
  ],
  [
    68, 11, 17, 7, 33, 3, 3, 29, 8, 0, 3, 1, 2, 13, 2, 10, 0, 4, 1, 7, 3, 31, 1,
    0, 7, 7, 8, 4, 23, 4, 2, 2, 29, 7, 2, 1, 16, 4, 19, 1, 23, 5, 2, 4, 18, 3,
    34, 4, 3, 1, 18, 3,
  ],
  [
    84, 11, 18, 14, 46, 9, 8, 45, 19, 1, 7, 3, 7, 15, 3, 21, 1, 9, 3, 6, 4, 71,
    0, 1, 16, 3, 17, 5, 37, 7, 2, 0, 10, 7, 8, 1, 17, 0, 22, 15, 25, 7, 3, 5,
    13, 3, 37, 0, 5, 1, 18, 7,
  ],
  [
    41, 0, 3, 14, 26, 6, 6, 35, 14, 1, 4, 2, 6, 7, 2, 12, 1, 7, 2, 3, 2, 56, 0,
    1, 12, 3, 10, 1, 7, 5, 1, 0, 2, 4, 8, 1, 15, 0, 8, 15, 3, 3, 0, 2, 11, 3,
    20, 0, 1, 1, 18, 6,
  ],
  [
    23, 0, 2, 2, 15, 1, 1, 17, 1, 0, 0, 0, 1, 5, 1, 3, 0, 3, 0, 2, 1, 20, 0, 0,
    3, 1, 4, 1, 2, 4, 0, 0, 2, 1, 2, 0, 12, 0, 3, 1, 3, 2, 0, 2, 1, 2, 0, 0, 0,
    1, 18, 0,
  ],
  [
    67, 0, 15, 11, 41, 7, 8, 36, 13, 1, 5, 3, 7, 14, 2, 13, 0, 7, 2, 7, 4, 56,
    1, 1, 12, 9, 10, 1, 11, 5, 2, 1, 9, 10, 8, 1, 22, 0, 8, 15, 8, 8, 0, 4, 13,
    3, 40, 0, 6, 1, 18, 5,
  ],
  [
    0, 0, 0, 0, 2, 0, 0, 4, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 1, 0, 0, 0,
    0, 1, 0, 4, 0, 0, 1, 19, 0, 0, 0, 0, 4, 2, 0, 0, 0, 0, 0, 13, 1, 6, 4, 0, 0,
    0, 0,
  ],
  [
    42, 11, 6, 10, 28, 6, 5, 36, 13, 1, 6, 2, 6, 6, 3, 18, 0, 6, 3, 3, 2, 52, 0,
    0, 9, 3, 14, 4, 25, 5, 0, 1, 23, 5, 6, 1, 14, 4, 24, 15, 20, 5, 2, 2, 22, 4,
    24, 4, 2, 1, 18, 5,
  ],
  [
    31, 0, 3, 6, 24, 5, 5, 31, 8, 1, 4, 2, 6, 6, 2, 12, 0, 6, 2, 3, 2, 43, 0, 0,
    8, 2, 9, 1, 5, 5, 0, 0, 2, 2, 6, 1, 14, 0, 8, 14, 3, 2, 0, 2, 9, 3, 22, 0,
    0, 1, 18, 2,
  ],
  [
    82, 11, 17, 10, 39, 5, 3, 35, 13, 0, 3, 1, 2, 18, 2, 13, 1, 6, 1, 7, 3, 49,
    1, 0, 11, 8, 13, 5, 36, 6, 1, 1, 11, 9, 2, 1, 16, 0, 17, 1, 26, 8, 3, 5, 5,
    3, 20, 0, 5, 1, 18, 5,
  ],
  [
    56, 0, 14, 10, 29, 3, 3, 23, 4, 0, 1, 1, 2, 13, 1, 4, 0, 5, 0, 5, 3, 28, 1,
    0, 4, 8, 5, 1, 6, 4, 1, 1, 9, 6, 3, 1, 16, 0, 3, 1, 8, 11, 0, 4, 12, 2, 67,
    0, 22, 1, 18, 5,
  ],
  [
    21, 11, 3, 4, 12, 2, 0, 11, 10, 0, 2, 0, 0, 2, 1, 9, 0, 1, 1, 2, 0, 11, 0,
    0, 5, 0, 8, 4, 33, 2, 0, 0, 2, 3, 0, 0, 0, 0, 14, 0, 20, 0, 2, 1, 0, 0, 0,
    0, 0, 0, 0, 3,
  ],
  [
    60, 0, 15, 4, 38, 3, 3, 32, 8, 0, 1, 1, 2, 13, 1, 7, 0, 7, 0, 6, 3, 42, 0,
    0, 8, 2, 9, 2, 17, 6, 1, 0, 8, 7, 2, 1, 14, 0, 3, 1, 10, 4, 1, 4, 5, 3, 20,
    0, 3, 1, 18, 2,
  ],
  [
    58, 0, 16, 12, 39, 6, 7, 36, 12, 1, 5, 3, 7, 12, 2, 13, 0, 9, 2, 6, 4, 54,
    0, 0, 9, 2, 10, 1, 13, 5, 2, 1, 27, 8, 6, 1, 16, 4, 10, 15, 6, 12, 0, 5, 30,
    4, 91, 4, 22, 1, 18, 8,
  ],
  [
    46, 0, 3, 7, 26, 5, 5, 35, 9, 1, 4, 2, 6, 10, 2, 12, 0, 6, 2, 4, 2, 60, 0,
    0, 7, 3, 12, 1, 11, 5, 0, 1, 21, 4, 6, 1, 14, 4, 10, 15, 5, 5, 0, 2, 22, 2,
    24, 4, 4, 1, 18, 3,
  ],
  [
    43, 0, 16, 6, 28, 5, 6, 22, 11, 1, 5, 3, 6, 11, 1, 11, 0, 6, 2, 5, 3, 34, 1,
    0, 8, 7, 6, 1, 11, 3, 2, 2, 27, 7, 4, 0, 7, 4, 7, 15, 4, 4, 0, 3, 30, 2, 91,
    4, 5, 1, 5, 8,
  ],
  [
    0, 0, 0, 0, 2, 0, 0, 4, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 1, 0, 0, 0,
    0, 1, 0, 4, 0, 0, 1, 19, 0, 0, 0, 0, 4, 2, 0, 0, 0, 0, 0, 13, 1, 6, 4, 0, 0,
    0, 0,
  ],
  [
    36, 0, 14, 7, 20, 3, 2, 14, 3, 0, 1, 1, 2, 8, 1, 2, 0, 1, 0, 3, 2, 33, 0, 0,
    3, 2, 6, 1, 5, 2, 2, 0, 6, 5, 1, 0, 13, 0, 3, 0, 4, 11, 0, 2, 12, 2, 44, 0,
    22, 0, 1, 2,
  ],
  [
    24, 0, 2, 2, 16, 1, 1, 20, 1, 0, 0, 0, 1, 5, 1, 3, 0, 3, 0, 3, 1, 20, 0, 0,
    4, 1, 4, 1, 2, 4, 0, 0, 2, 1, 2, 1, 12, 0, 3, 1, 3, 2, 0, 2, 1, 2, 8, 0, 0,
    0, 18, 0,
  ],
  [
    33, 0, 2, 6, 16, 2, 2, 25, 6, 0, 0, 0, 1, 6, 1, 3, 1, 5, 0, 4, 1, 24, 0, 1,
    7, 1, 4, 1, 4, 4, 1, 0, 2, 2, 5, 1, 16, 0, 3, 1, 3, 9, 0, 2, 2, 2, 8, 0, 5,
    1, 18, 5,
  ],
  [
    41, 11, 9, 9, 18, 7, 5, 27, 15, 1, 7, 2, 5, 9, 2, 18, 1, 6, 3, 5, 1, 45, 0,
    1, 11, 2, 10, 4, 26, 1, 1, 0, 2, 5, 6, 0, 4, 0, 19, 14, 19, 1, 2, 1, 15, 1,
    65, 0, 2, 0, 2, 10,
  ],
];

export const Departments = [
  "Political-Military",
  "Political-Military & Internet Governance",
  "Internet",
  "Civil Rights",
  "Law Enforcement",
];
export const Departments2 = [
  "UN",
  "Regional organizations",
  "Industry & tech alliances",
  "Economic groupings",
  "Independent commissions",
  "Bilateral negotiations",
  "International law conventions",
  "Military alliances",
  "Arms control actors",
  "Internet policy standards",
  "Tech alliances",
  "Multi-stakeholders",
  "Civil society organizations",
  "Internet freedom organizations",
  "Multistakeholder initiatives",
  "Content agreements",
  "General telecommunications",
  "Organization standards",
  "UN",
];
export const Departments3 = [
  "UNGGE",
  "UNGA",
  "OSCE",
  "Microsoft",
  "G7",
  "G20",
  "GCSC",
  "SCO",
  "OAS",
  "China & United Kingdom",
  "China & United States",
  "United States & China",
  "Australia & China",
  "Authoritarian states", //*China; Russian Federation*//
  "United States, Baltics & Nordics", //* United States; Denmark; Estonia; Finland; Iceland; Latvia; Lithuania; Norway; Sweden*//
  "EU; United States",
  "United States & Baltics", //*; Estonia; Latvia; Lithuania*//
  "United States & Republic of Korea",
  "Russian Federation; India; China",
  "Russian Federation & Brazil",
  "United States & Russian Federation",
  "Russian Federation",
  "United States & Japan",
  "United States & GCC",
  "NATO",
  "EU",
  "BRICS",
  "ASEAN",
  "OECD",
  "APEC",
  "Nuclear Security Summit",
  "Packet Clearing House",
  "ISOC",
  "Cybersecurity Tech Accord",
  "Paris Call",
  "Carnegie Endowment for International Peace",
  "Freedom House",
  "Internet Governance Forum",
  "NETmundial",
  "Google",
  "UNSG",
  "International Consortium",
  "International Telecommunication Union",
  "ASEAN Regional Forum",
  "ETSI",
  "China",
  "ISO",
  "ICANN Committee on Security and Stability",
  "Internet & Jurisdiction Policy Network",
  "ICANN",
  "UNGGE",
];
