import React from "react";
import logo from "../../img/HCSS_Logo_Blauw_RGB1200 ppi.jpg";
import "./logo.css";

const Logo = () => {
  return (
    <div>
      <img src={logo} alt="HCSS Logo" className="logo-box" />
    </div>
  );
};

export default Logo;