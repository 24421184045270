import React, { useMemo } from "react";
import ChordDiagram from "../../react-chord/index";

function getRandomColor() {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

const Chord = ({ matrix, Departments, onGroupClick, setSelectedRibbon }) => {
  const ColorDepartments = useMemo(
    () => Departments.map(getRandomColor),
    [Departments]
  );

  function ribbonOnClick(ribbonIndex, { source, target }) {
    setSelectedRibbon([source.index, target.index]);
  }
  
  return (
    <ChordDiagram 
      matrix={matrix}
      componentId={3}
      width={630} //600
      height={360} //300
      style={{ padding: "50px" }} //25px 
      groupLabels={Departments}
      groupOnClick={onGroupClick}
      ribbonOnClick={ribbonOnClick}
      groupColors={ColorDepartments}
    />
  );
};

export default Chord;
