import React, { useEffect, useState } from "react";
import Graph from "react-graph-vis";
import "./nodeGraph.css";

export function generateColor() {
  return `rgb(${randomColorNumber()},${randomColorNumber()},${randomColorNumber()})`;
}
function randomColorNumber() {
  return Math.floor(Math.random() * 120) + 120;
}

function uuid() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

const NodeGraph = ({ data, onNodeClick, nodes }) => {
  let [graphKey, setGraphKey] = useState(uuid);


  useEffect(() => {
    setGraphKey(uuid());
  }, [data.length, nodes.length]);

  const options = {
    // layout: {
    //   hierarchical: false,
    // },

    edges: {
      color: "#c48469",
    },
    width: "1000px", //300px
    height: "360px", //300px
  };

  const events = {
    select: function (event) {
      var { nodes } = event;
      onNodeClick(nodes[0]);
    },
  };
  return (
    <Graph
      key={graphKey}
      graph={{ nodes: nodes, edges: data }}
      options={options}
      events={events}
      getNetwork={(network) => { }}
    />
  );
};

export default NodeGraph;
