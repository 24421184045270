import React from "react";
import "./table.css";

const Table = ({ data }) => {
  return (
    <div className="tableContainer">
      <label>Actor:</label>
      <span align="cebd">{data["Regime Name"]}</span>
      <label>Category:</label>
      <span align="ebd">{data["full_norms"]}</span>
      <label>Topic:</label>
      <span align="end">{data["macro_group"]}</span>
      <label>Document:</label>
      <span align="end">{data["Regime Document"]}</span>
      <label>Norm Statement:</label>
      <span align="end">{data["Regime Norm"]}</span>
    </div>
  );
};

export default Table;
