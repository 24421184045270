import React, { useEffect, useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Select, MenuItem, InputLabel } from "@mui/material";
import NodeGraph, { generateColor } from "../nodeGraph/NodeGraph";
import Chord from "../chord/Chord";
import Bar from "../bar/Bar";
import {
  Departments,
  Departments2,
  Departments3,
  matrix1,
  matrix2,
  matrix3,
} from "../data/host";
import "./graphs.css";
import best from "../data/bestPractice.json";
import norms from "../data/norms.json";
import cbm from "../data/cbm.json";
import initiative from "../data/initiative.json";
import principles from "../data/principles.json";
import vertex from "../data/vertices/vertex100.json";
import Table from "../table/table";
import TransparentContainer from "../transparentContainer/TransparentContainer";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

const Graphs = () => {
  const [showComponent, setShowComponent] = useState(true);
  const [mode, setMode] = useState("Regime Macro Group");
  const [barData, setBarData] = useState([]);
  const [barLabels, setBarLabels] = useState([]);
  const [selectNetwork, setSelectNetwork] = useState("norms");
  const [selectNetworkColor, setSelectNetworkColor] =
    useState("Regime Macro Group");
  const [selectNetworkSize, setSelectNetworkSize] = useState("n_degree");
  const [selectFilter, setSelectFilter] = useState();
  const [selectedNode, setSelectedNode] = useState();
  const [selectedGroup, setSelectedGroup] = useState();
  const [selectedRibbon, setSelectedRibbon] = useState([]);
  // const [nodeGraphKey,setNodeGraph]

  const { departments, matrix } = useMemo(() => {
    if (mode === "Regime Macro Group") {
      return { departments: Departments, matrix: matrix1 };
    } else if (mode === "Regime Host") {
      return { departments: Departments2, matrix: matrix2 };
    } else if (mode === "Regime Name") {
      return { departments: Departments3, matrix: matrix3 };
    }
  }, [mode]);

  function onGroupClick(index) {
    setBarData([
      {
        name: departments[index],
        data: matrix[index],
      },
    ]);
    setBarLabels(departments);
    setSelectedGroup(departments[index]);
  }
  //Networks
  const nodeData = useMemo(() => {
    switch (selectNetwork) {
      case "best":
        return best;
      case "norms":
        return norms;
      case "cbm":
        return cbm;
      case "initiative":
        return initiative;
      case "principles":
        return principles;
      default:
        return [];
    }
  }, [selectNetwork]);

  const vertexData = useMemo(() => {
    switch (selectNetwork) {
      case "best":
        return vertex.filter((item) => item["Norm Status"] === "best practice");
      case "norms":
        return vertex.filter((item) => item["Norm Status"] === "norm");
      case "cbm":
        return vertex.filter((item) => item["Norm Status"] === "cbm");
      case "initiative":
        return vertex.filter((item) => item["Norm Status"] === "initiative");
      case "principles":
        return vertex.filter((item) => item["Norm Status"] === "principle");
      default:
        return [];
    }
  }, [selectNetwork]);

  //Groups

  const groupedVertexes = useMemo(() => {
    return Object.values(
      vertex.reduce(function (r, a) {
        r[a[selectNetworkColor]] = r[a[selectNetworkColor]] || [];
        r[a[selectNetworkColor]].push(a.id);
        return r;
      }, {})
    );
  }, [selectNetworkColor]);

  const tableData = useMemo(() => {
    return vertex.find((item) => item.id === selectedNode);
  }, [selectNetwork, selectedNode]);

  const colorList = useMemo(
    () => groupedVertexes.map(() => generateColor()),
    [groupedVertexes]
  );

  const groups = useMemo(() => {
    const array = new Set();
    vertex.forEach((item) => array.add(item.macro_group));
    return Array.from(array);
  }, []);

  const barData1 = useMemo(() => {
    const list = groups.map((group) => {
      const sourceCount = vertex.filter((item) => {
        return (
          item[mode] === departments[selectedRibbon[0]] &&
          item.macro_group === group
        );
      }).length;
      const target = vertex.filter(
        (item) =>
          item[mode] === departments[selectedRibbon[1]] &&
          item.macro_group === group
      ).length;
      return { sourceCount, target };
    });
    const barData = [
      {
        name: departments[selectedRibbon[0]],
        data: list.map((item) => item.sourceCount),
      },
      {
        name: departments[selectedRibbon[1]],
        data: list.map((item) => item.target),
      },
    ];
    return barData;
  }, [mode, groups, selectedRibbon, departments]);

  function generateFontSize(vertexFound) {
    if (selectNetworkSize === "n_degree") {
      return 14 + Math.floor(vertexFound.n_degree * 20);
    } else if (selectNetworkSize === "n_between") {
      return 14 + Math.floor(vertexFound.n_between * 4);
    } else if (selectNetworkSize === "n_vector") {
      return 14 + Math.floor(vertexFound.n_vector * 3);
    }
  }
  const nodes = useMemo(() => {
    const uniqueNodes = [];
    // Networks data
    nodeData.forEach((item) => {
      if (uniqueNodes.every((i) => i.id !== item.from)) {
        const id = item.from;
        const indexColor = groupedVertexes.findIndex((array) =>
          array.includes(id)
        );

        const foundVertex = vertex.find((item) => item.id === id);

        if (!selectFilter || foundVertex[mode] === selectFilter)
          uniqueNodes.push({
            id: id,
            label: "NORM\n ID " + item.from,
            title: "node " + item.from,
            color: colorList[indexColor],
            borderWith: 10,
            size: 10,
            font: {
              size: generateFontSize(foundVertex)/4,
            },
          });
      }
      if (uniqueNodes.every((i) => i.id !== item.to)) {
        const id = item.to;
        const indexColor = groupedVertexes.findIndex((array) =>
          array.includes(id)
        );
        const foundVertex = vertex.find((item) => item.id === id);
        console.log(selectFilter, foundVertex[mode]);
        if (!selectFilter || foundVertex[mode] === selectFilter)
          uniqueNodes.push({
            id: id,
            label: "NORM\n ID " + item.to,
            title: "node " + item.to,
            color: colorList[indexColor],
            font: {
              size: generateFontSize(foundVertex)/4,
            },
          });
      }
    });
    return uniqueNodes;
  }, [
    nodeData,
    groupedVertexes,
    colorList,
    selectNetworkSize,
    mode,
    selectFilter,
  ]);

  const filterSelectOptions = useMemo(() => {
    const filterSelectOptions = new Set();

    // Networks data
    vertexData.forEach((item) => {
      filterSelectOptions.add(item[mode]);
    });
    return Array.from(filterSelectOptions);
  }, [mode, vertexData]);

  useEffect(() => {
    setSelectFilter("");
  }, [nodeData, mode]);

  const onNodeClick = (nodeId) => {
    setSelectedNode(nodeId);
  };

  return (
    <div>{showComponent && (
      <TransparentContainer setShowComponent={setShowComponent} />
        )}    
    <Grid container align = "end" spacing={1} style={{ padding: "10px" }}>
      <Grid item xs={40}>
       </Grid>
      <Grid item xs={4.1}>
        <p align = "end"><font color="grey">How to group connections among actors in cyberspace?</font></p>
        <Stack direction="row" spacing={10}>
        <Item>
            <FormControlLabel
              control={
                <Android12Switch 
                  checked={mode === "Regime Macro Group"}
                  onChange={() => setMode("Regime Macro Group")}
                />
              }
              label="Stakeholder Domain"
            />
          </Item>

        <Item>
            <FormControlLabel
              control={
                <Android12Switch
                  checked={mode === "Regime Host"}
                  onChange={() => setMode("Regime Host")}
                />
              }
              label="Actor Type"
            />
          </Item>
        <Item>
            <FormControlLabel
              control={
                <Android12Switch
                  checked={mode === "Regime Name"}
                  onChange={() => setMode("Regime Name")}
                />
              }
              label="Actor Name"
            />
          </Item>
        </Stack>
        <Stack>
          <Chord
            Departments={departments}
            matrix={matrix}
            setBarData={setBarData}
            setBarLabels={setBarLabels}
            onGroupClick={onGroupClick}
            setSelectedRibbon={setSelectedRibbon}
          />
        </Stack>
      </Grid>
      <Grid container item xs={7} direction="row" spacing={5} align = "end">
      <Grid item xs={4} align = "end">
          <div>
            <InputLabel id="network-label"  align = "end">Actor:</InputLabel>
            <Select
              value={selectFilter}
              style={{ minWidth: "6rem" }}
              onChange={(e) => {
                const lastNetwork = selectNetwork;
                setSelectNetwork("");

                setTimeout(() => {
                  setSelectNetwork(lastNetwork);
                }, 50);
                setTimeout(() => {
                  setSelectFilter(e.target.value);
                }, 150);
              }}
            >
              {filterSelectOptions.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div>
            <InputLabel id="network-label" align ="end">Group:</InputLabel>
            <Select
              labelId="network-label"
              value={selectNetworkColor}
              onChange={(e) => {
                setSelectNetworkColor(e.target.value);
              }}
            >
              <MenuItem value="Regime Macro Group">Stakeholder Domain</MenuItem>
              <MenuItem value="Regime Host">Actor Type</MenuItem>
              <MenuItem value="Regime Name">Actor Name</MenuItem>
              <MenuItem value="Norm Category">Category</MenuItem>
              <MenuItem value="macro_group">Topic</MenuItem>

            </Select>
          </div>
        </Grid>
        <Grid item xs={3.1}>
          <div>
            <InputLabel id="network-label" align = "end">Granularity:</InputLabel>
            <Select
              labelId="network-label"
              value={selectNetwork}
              onChange={(e) => {
                setSelectNetwork("");
                setTimeout(() => {
                  setSelectNetwork(e.target.value);
                }, 10);
              }}
            >
              <MenuItem value="norms">Proper Norms</MenuItem>
              <MenuItem value="principles">Principles</MenuItem>
              <MenuItem value="initiative">Initiatives</MenuItem>
              <MenuItem value="best">Best Practice</MenuItem>
              <MenuItem value="cbm">CBM</MenuItem>
            </Select>
          </div>
        </Grid>
        <Grid item xs={1.9}>
          <div>
            <InputLabel id="network-label" align = "end">Importance:</InputLabel>
            <Select
              labelId="network-label"
              value={selectNetworkSize}
              onChange={(e) => {
                setSelectNetworkSize(e.target.value);
              }}
            >
              <MenuItem value="n_degree">Degree</MenuItem>
              <MenuItem value="n_between">Betweenness</MenuItem>
              <MenuItem value="n_vector">Eigenvector</MenuItem>
            </Select>
          </div>
        </Grid>
        <Grid xs={18}>
          {nodeData.length > 0 && (
            <NodeGraph
              data={nodeData}
              selectedGroup={selectedGroup}
              onNodeClick={onNodeClick}
              nodes={nodes}
            /*nodeColor={nodeColor}*/
            />
          )}
        </Grid>
      </Grid>
      <Grid item xs={7}>
       <Grid align = "center" color = "grey"> Connectivity Profile between Connected Actors </Grid>
        <Bar data={barData1} labels={groups} />
      </Grid>
      <Grid className="Graph-container-table" item xs={5}>
      <Grid align = "center" color="grey"> Metadata & Statement of Norm </Grid>
        {tableData && <Table data={tableData} />}
      </Grid>
    </Grid>
</div>
  );
};

export default Graphs;
